<template>
  <div class="background-default" style="min-height: 100vh;">
    <unAuthorized/>
    <errorNetwork/>
    <div class="pt-10 mt-10">
      <v-img
        class="mx-auto"
        width="200"
        :src="require('@/assets/img/yureka_logo.png')">
      </v-img>
      <!-- <div class="welcome-text text-center mb-5">Welcome to CMS by Yureka</div> -->
      <v-row align="center" justify="center" class="px-8">
        <v-col cols="12" md="4"></v-col>
        <v-col cols="12" md="4">
          <ValidationObserver ref="observer">
            <v-form class="text-left mb-5">
              <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                <div class="grey--text mb-2">Email</div>
                <v-text-field
                  v-on:keyup.enter="save" 
                  outlined
                  dense
                  autocomplete="off"
                  single-line
                  color="#F05326"
                  placeholder="Email"
                  v-model="form.email"
                  prepend-inner-icon="mdi-email"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>
              <ValidationProvider
                name="Password"
                rules="required"
                v-slot="{ errors }">
                <div class="grey--text mb-2">Password</div>
                <v-text-field
                  v-on:keyup.enter="save"
                  class="mt-2 mb-1"
                  outlined
                  color="#F05326"
                  dense
                  autocomplete="off"
                  single-line
                  placeholder="Password"
                  v-model="form.password"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  prepend-inner-icon="mdi-lock"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>
            </v-form>
          </ValidationObserver>
          <div class="text-center">
            <v-alert type="error" text dense prominent 
              v-show="error.message.length > 0"
              v-html="error.message">
            </v-alert>
            <v-btn
              block
              elevation="3"
              color="#F05326"
              :disabled="$store.state.process.run"
              :loading="$store.state.process.run"
              v-on:keyup.enter="save"
              @click="save"
              class="white--text text-capitalize">
              Masuk
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="4"></v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import { TokenService } from "../../service/Storage.Service";
  import { get, post, put, destroy } from "@/service/Axios";
  import unAuthorized from "@/components/401.vue";
  import errorNetwork from "@/components/500.vue";
  export default {
    components: {
      unAuthorized,
      errorNetwork
    },
    data() {
      return {
        dialog: {
          errorNetwork: false
        },
        show: false,
        form: {
          email: "",
          password: "",
          // email: "admin1@admin.com",
          // password: "admin1",
          fcm_token: localStorage.getItem('TOKEN_FCM'),
        },
        process: {
          run: false
        },
        error: {
          message: ""
        }
      }
    },
    created(){
      console.clear()
    },
    methods: {
      toUpper(){
        this.form.access_code = this.form.access_code.toUpperCase()
      },
      async save() {
        this.error.message = ""
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          this.$store.state.process.run = true
          await post(`api/v1/auth/login`, {
            data: {
              email: this.form.email,
              password: this.form.password,
              access_key: process.env.VUE_APP_ACCESS_KEY,
              // access_key: 'd986c63c-0982-46c6-8aed-2b16d72e1633',
            }
          }).then(response => {
            let res = response.data
            if (res.status === 200) {
              this.form = {
                username: "",
                password: "",
                access_code: "",
              }
              this.$refs.observer.reset()

              TokenService.saveToken(
                res.data.token,
                JSON.stringify(res.data)
              )
              // this.$router.push('/dashboard')
              window.location = `${process.env.BASE_URL}`
            } else {
              this.$store.state.process.run = false
              this.error.message = res.msg
            }
          }).catch( error => {
            if (error.response) {
              let errorData = error.response.data;
              let errorCode = error.response.status;
              let errorHeader = error.response.headers;

              if (errorCode === 401) {
                // this.$store.state.unauthorization = true
                this.$store.state.process.run = false
                this.process.form = false
              }
            }else{
              // this.$store.state.errorNetwork = true
              this.$store.state.process.run = false
              this.process.form = false
            }
          })
        }
      }
    }
  }
</script>

<style>
.welcome-text{
  font-family: 'Roboto';
  font-weight: 500;
  color: #F05326;
  font-size: 34px;

}
</style>